// Import libraries
import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import Img from 'gatsby-image';


// Import global styles
import GlobalStyle from '../styles/global';


// Import component
import Layout from '../layout/Layout';
import SEO from '../layout/SEO';


// Component styles
const StyledBanner = styled.div`
    background-image: url(${props => !!props.image.childImageSharp ? props.image.childImageSharp.fluid.src : props.image});
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    height: calc(100vh - 15rem);

    & .content {
        display: grid;
        grid-template-rows: 1fr 6rem;
        align-items: center;
        justify-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .6);

        & .container {
            display: block;
            padding: 0 var(--padding-right) 0 var(--padding-left);
            width: 100%;
            max-width: var(--max-width);
        }

        & .inner h1 {
            font-size: calc(40px + (80 - 40) * ((100vw - 320px) / (2200 - 320)));
            line-height: 1.2;
            color: #fff;
            
            & .primary {
                color: var(--color-primary);
            }

            @media only screen and (min-width: 1600px) {
                font-size: 55px;
            }

            @media only screen and (min-width: 1900px) {
                font-size: 70px;
            }
        }

        & .scroll-down-button {
            display: grid;
            align-items: center;
            justify-items: center;
            width: 6rem;
            height: 6rem;
            font-size: 2.5rem;
            background-color: #fff;
            color: #000;
            cursor: pointer;
            outline: 0;
            transition: background-color .25s ease-in-out,
                        color .25s ease-in-out;
            
            &:hover {
                background-color: var(--color-primary);
                color: #fff;
            }
        }
    }
`;

const StyledOurFeatures = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media only screen and (max-width: 800px) {
        grid-template-columns: 1fr;
    }

    & .feature.content {
        display: grid;
        grid-template-rows: repeat(3, max-content);
        align-content: center;
        grid-row-gap: 2rem;
        padding: var(--padding-right) var(--padding-right) var(--padding-left) var(--padding-left);
        background-color: var(--color-background-2);

        & h1 {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }

        & .feature__summary {
            font-size: var(--font-size-body-p);
            font-weight: var(--font-weight-body-p);
            line-height: var(--line-height-body-p);

            & a:link,
            & a:visited {
                color: currentColor;
                text-decoration: none;
                outline: 0;
            }

            & a:hover,
            & a:focus,
            & a:active {
                color: currentColor;
                text-decoration: underline;
                outline: 0;
            }
        }

        & .content-link {
            justify-self: start;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            background-color: var(--color-primary);
            border: 2px solid var(--color-primary);
            border-radius: 0;
            font-size: 2rem;
            padding: 1rem 2rem;
            color: #fff;
            font-weight: 400;

            & span {
                margin-right: 10px;
            }
        }

        & .content-link:link,
        & .content-link:visited {
            color: #fff;
            text-decoration: none;
            outline: 0;
            transition: color .25s ease;
        }

        & .content-link:hover,
        & .content-link:focus {
            text-decoration: none;
            color: #fff;
            outline: 0;
            transform: translateY(-3px);
            box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
        }

        & .content-link:active {
            text-decoration: none;
            color: #fff;
            outline: 0;
            transform: translateY(0);
            box-shadow: none;
        }
    }

    & .container.image {
        & .gatsby-image-wrapper {
            height: 100%;
        }

        @media only screen and (max-width: 800px) {
            &--2 {
                grid-row: 4 / 5;
            }

            &--4 {
                grid-row: 8 / 9;
            }
        }
    }
`;

const StyledActionBanner = styled.div`
    & .content {
        display: grid;
        grid-template-columns: 75% 1fr;
        grid-row-gap: 1rem;
        padding: var(--padding-right);
        background-color: var(--color-secondary);
        color: #fff;
        
        @media only screen and (max-width: 950px) {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, min-content);
            grid-row-gap: 5rem;
        }

        & h1 {
            font-size: 5rem;
            font-weight: 100;
            line-height: var(--line-height-body-h1);
        }

        & h2 {
            font-size: 5rem;
            font-weight: 400;
            line-height: var(--line-height-body-h2);
            color: var(--color-primary);

            & .small-text {
                font-size: 1.5rem;
                font-weight: 100;
            }

            @media only screen and (max-width: 950px) {
                margin-top: -4rem;
            }
        }

        &-link {
            grid-column: 2 / 3;
            grid-row: 1 / 3;
            align-self: center;
            justify-self: center;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            background-color: var(--color-primary);
            border: 2px solid var(--color-primary);
            border-radius: 0;
            font-size: 2rem;
            padding: 1rem 2rem;
            color: #fff;
            font-weight: 400;
            text-decoration: none;

            & span {
                margin-right: 10px;
            }

            @media only screen and (max-width: 950px) {
                grid-column: 1 / -1;
                grid-row: 3 / 4;
                justify-self: start;
            }
        }

        &-link:link,
        &-link:visited {
            color: currentColor;
            text-decoration: none;
            outline: 0;
            transition: color .25s ease;
        }

        &-link:hover,
        &-link:focus {
            text-decoration: none;
            color: currentColor;
            outline: 0;
            transform: translateY(-3px);
            box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
        }

        &-link:active {
            text-decoration: none;
            color: currentColor;
            outline: 0;
            transform: translateY(0);
            box-shadow: none;
        }
    }
`;


// Create component
const ParticulierPagina = () => {
    const data = useStaticQuery(graphql`
        query {
            bannerImage: file(relativePath: { eq: "banner/particulier/Administratie-voor-particuliere.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }



            featuredService1Image: file(relativePath: { eq: "services/particulier/featured/Aangifte-Inkomstenbelasting.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            featuredService2Image: file(relativePath: { eq: "services/particulier/featured/Hypotheek-advies.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            featuredService3Image: file(relativePath: { eq: "services/particulier/featured/Pensioen-advies.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            featuredService4Image: file(relativePath: { eq: "services/particulier/featured/Erf-en-schenkbelasting.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);


    // Handle scrolling down to next section on page
    const handleScrollDown = () => {
        // Get header height difference between static header height and scollling header height and get banner height
        const headerHeightDifference = (parseFloat(window.getComputedStyle(document.querySelector('html')).getPropertyValue('font-size')) * 15) - (parseFloat(window.getComputedStyle(document.querySelector('html')).getPropertyValue('font-size')) * 10);
        const bannerHeight = document.querySelector('.banner').offsetHeight;
        

        // Scroll to place underneath banner
        window.scrollTo({
            top: (bannerHeight + headerHeightDifference),
            left: 0,
            behavior: 'smooth'
        });
    };


    return (
        <React.Fragment>
            <GlobalStyle />
            <SEO
                title="Particulier"
                description="Als particulier bent u bij ons administratiekantoor ook aan het juiste adres. Aangifte inkomstenbelasting, hypotheek- en pensioen advies en nog veel meer. "
            />
            <Layout>
                <StyledBanner image={data.bannerImage} className="banner">
                    <div className="content">
                        <div className="inner container">
                            <h1>Als <span className="primary">particulier</span> bent u bij ons ook aan het <span className="primary">juiste adres</span>.</h1>
                        </div>

                        <div className="container">
                            <div className="scroll-down-button" role="button" tabIndex={0} onClick={handleScrollDown} onKeyDown={handleScrollDown}>
                                <FontAwesomeIcon icon={faArrowDown} className="icon" />
                            </div>
                        </div>
                    </div>
                </StyledBanner>
                
                <StyledOurFeatures>
                    <div className="feature content">
                        <h1>Aangifte inkomstenbelasting</h1>

                        <p className="feature__summary">Is uw aangifte inkomstenbelasting een ergernis? Bent u onzeker of u er wel uithaalt wat erin zit of heeft u gewoon geen tijd om aangifte te doen? Laat het aan Up2Date Administratie over. Dan heeft u er geen omkijken meer naar en weet u zeker dat er geen enkele aftrekpost over het hoofd wordt gezien. Voor de kosten hoeft u het niet te laten, want wij rekenen slechts &euro;75,- incl BTW per aangifte waarbij fiscale partners ook nog eens gratis zijn.</p>

                        <Link to="/" className="content-link">Meer weten</Link>
                    </div>

                    <div className="container image image--1">
                        <Img fluid={data.featuredService1Image.childImageSharp.fluid} alt="" className="" />
                    </div>

                    <div className="container image image--2">
                        <Img fluid={data.featuredService2Image.childImageSharp.fluid} alt="" className="" />
                    </div>

                    <div className="feature content">
                        <h1>Hypotheek advies</h1>

                        <p className="feature__summary">Ons kantoor heeft een rijk netwerk aan betrouwbare partners. Voor hypotheek- en verzekeringsadvies werken wij veelal samen met onze partner <a href="https://mskgroep.nl" target="_blank" rel="noopener noreferrer">MSK Groep</a>. Samen met hen kunnen wij u voorzien van passend advies op het gebied van hypotheken en verzekeringen.</p>

                        <Link to="/" className="content-link">Meer weten</Link>
                    </div>

                    <div className="feature content">
                        <h1>Pensioen advies</h1>

                        <p className="feature__summary">Heeft u vragen met betrekking tot (aanvullend) pensioenopbouw of komt uw pensioen of lijfrente binnenkort tot uitkering? Ook dan staan wij voor u klaar en adviseren wij u graag zodat u niet voor verrassingen komt te staan.</p>

                        <Link to="/" className="content-link">Meer weten</Link>
                    </div>

                    <div className="container image image--3">
                        <Img fluid={data.featuredService3Image.childImageSharp.fluid} alt="" className="" />
                    </div>

                    <div className="container image image--4">
                        <Img fluid={data.featuredService4Image.childImageSharp.fluid} alt="" className="" />
                    </div>

                    <div className="feature content">
                        <h1>Erf- en Schenkbelasting</h1>

                        <p className="feature__summary">Tijdens het krijgen van een erfenis of schenking moet er vaak belasting betaald worden. Wij brengen u graag op de hoogte van de actuele wet- en regelgeving zodat u voorbereid bent. Naast het afwikkelen van de belasting, adviseren wij u ook graag op het gebied van eventuele vrijstellingen in het kader van belastingbesparing. Er zijn namelijk diverse vrijstellingen die schenken fiscaal heel aantrekkelijk kan maken.</p>

                        <Link to="/" className="content-link">Meer weten</Link>
                    </div>
                </StyledOurFeatures>

                <StyledActionBanner>
                    <div className="content">
                        <h1>Uw aangifte inkomstenbelasting</h1>
                        <h2>Voor maar &euro;75,- <span className="small-text">(fiscale partners gratis)</span></h2>

                        <Link to="/contact" className="content-link">
                            <span>Neem contact op</span>
                            <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                        </Link>
                    </div>
                </StyledActionBanner>
            </Layout>
        </React.Fragment>
    );
};


// Export component by default
export default ParticulierPagina;